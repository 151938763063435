@import 'variables';

#section-nav{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    
    .menu-group{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;

        @media (max-width: $bp-tablet){
            display: none;
        }
        
        .menu-item{
            width: fit-content;
            margin: 5px 10px;
            border: 3px solid white;
            border-radius: 20px;
            position: relative;
            
            .menu-item-group{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                color: white;
                margin: 10px 20px;
                
                .menu-item-icon i{
                    font-size: 20px;
                }
                
                .menu-item-title p{
                    margin: 0 10px;
                    font-size: 15px;
                    text-align: center;
                }
            }
            
            &:hover{
                border: 3px solid #3E5823;
                background-color: white;
                
                i{
                    color: #3E5823;
                }
                
                p{
                    color: #3E5823;
                }

                .submenu-group{
                    transform: scaleY(1);
                    max-height: fit-content;
                    outline: 3px solid #3E5823;
                }
            }
            
            .submenu-group{
                max-height:0px;
                transform: scaleY(0);
                transform-origin: top;
                width: 100%;
                overflow: hidden;
                transition: transform 0.5s ease-in-out;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
                margin: 0;
                position: absolute;
                z-index: 4;
                background-color: white;
                border-radius: 0 0 20px 20px;
                
                .submenu-item{
                    width: 100%;
                    height: 100%;
                    border: none;
                    padding: 10px 0;
                    text-align: center;
                    
                    a{
                        width: 100%;
                        height: 100%;
                    }

                    &:last-of-type{
                        border-radius: 0 0 10px 10px;
                    }

                    &:hover{
                        background-color: #3E5823;

                        a{
                            color: white;
                        }
                    }
                }
            }
        }
        
        .menu-item-has-child:hover{
            border-radius: 20px 20px 0 0;
        }
    }

}
#menu-burger{
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #3E5823;

    @media (min-width: $bp-tablet){
        display: none;
    }
    
    .menu-item{
        width: fit-content;
        margin: 5px 10px;
        border: 3px solid white;
        border-radius: 20px;
        position: relative;
        
        .menu-item-group{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            color: white;
            margin: 10px 20px;
            
            .menu-item-icon i{
                font-size: 20px;
            }
            
            .menu-item-title p{
                margin: 0 10px;
                font-size: 15px;
                text-align: center;
            }
        }
        
        &:hover{
            border: 3px solid #3E5823;
            background-color: white;
            
            i{
                color: #3E5823;
            }
            
            p{
                color: #3E5823;
            }

            .submenu-group{
                transform: scaleY(1);
                max-height: fit-content;
                outline: 3px solid #3E5823;
            }
        }
        
        .submenu-group{
            max-height:0px;
            transform: scaleY(0);
            transform-origin: top;
            width: 100%;
            overflow: hidden;
            transition: transform 0.5s ease-in-out;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            margin: 0;
            position: absolute;
            z-index: 4;
            background-color: white;
            border-radius: 0 0 20px 20px;
            
            .submenu-item{
                width: 100%;
                height: 100%;
                border: none;
                padding: 10px 0;
                text-align: center;
                
                a{
                    width: 100%;
                    height: 100%;
                }

                &:last-of-type{
                    border-radius: 0 0 10px 10px;
                }

                &:hover{
                    background-color: #3E5823;

                    a{
                        color: white;
                    }
                }
            }
        }
    }
    
    .menu-item-has-child:hover{
        border-radius: 20px 20px 0 0;
    }
}